import Button from '@mui/material/Button';
import QRCode from 'qrcode';
import React from 'react';
import { createRoot } from 'react-dom/client';

const ImageExport = ({ selectedCards, selectedCardType, deckLink }) => {
    const canvasHeight = 1600;
    const canvasWidth = 1600;
    const cardPaddingBottom = 10;
    const containerPadding = 10;
    const headerImageSize = { width: 300, height: 150 };

    const colors = selectedCards.flatMap(card => card['Color'] || []);
    const uniqueColors = Array.from(new Set(colors.map(color => color.toLowerCase())));

    const uniqueCards = [...new Set(
        selectedCards
            .filter(card => card.Category.toLowerCase() !== 'leader')  // Exclude cards with the category "leader"
            .map(card => card.Key)
    )];

    const cardCount = uniqueCards.length;

    let cardWidth = 240;
    let cardHeight = 335;
    let cardScale = 1;
    console.log(cardCount + " is the count");
    // Adjust card size based on the number of cards
    if (cardCount <= 12) {
        console.log("<= 13");
        cardScale = 1.365;  // 200% size
    } else if (cardCount <= 15) {
        console.log("<= 20");
        cardScale = 1.25;  // 150% size
    } else if (cardCount <= 24) {
        console.log("<= 24");
        cardScale = 1;
    }

    cardWidth *= cardScale;
    cardHeight *= cardScale;

    // Calculate the max cards per row based on the card size and canvas width
    const availableWidth = canvasWidth - 2 * containerPadding;  // Subtract padding from both sides
    let maxCardsPerRow = Math.floor(availableWidth / cardWidth);

    // Calculate the number of rows needed to fit all cards
    let numberOfRows = Math.ceil(cardCount / maxCardsPerRow);

    // Calculate the total height required for all rows
    let totalCardHeight = numberOfRows * (cardHeight + cardPaddingBottom);

    // Determine if the cards will fit within the canvas height
    const availableHeight = canvasHeight - 2 * containerPadding;  // Subtract padding from top and bottom
    let fitsInCanvas = totalCardHeight <= availableHeight;

    // If the cards don't fit, scale them down proportionally to fit within the canvas
    if (!fitsInCanvas) {
        const scaleFactor = availableHeight / totalCardHeight;
        cardWidth *= scaleFactor;
        cardHeight *= scaleFactor;
        maxCardsPerRow = Math.floor(availableWidth / cardWidth);
        numberOfRows = Math.ceil(cardCount / maxCardsPerRow);
        totalCardHeight = numberOfRows * (cardHeight + cardPaddingBottom);
        fitsInCanvas = totalCardHeight <= availableHeight;
    }

    if (!fitsInCanvas) {
        console.log('Cards still don\'t fit in the canvas. Further adjustments are needed.');
    } else {
        console.log('Cards fit within the canvas.');
    }

    // Now you can use cardWidth, cardHeight, maxCardsPerRow, and numberOfRows
    // to layout your cards within the canvas.

    const categoryCount = selectedCards.reduce((counts, card) => {
        counts[card.Category] = (counts[card.Category] || 0) + card.count;
        return counts;
    }, {});

    const counterCounts = selectedCards.reduce((acc, card) => {
        if (card.Category === "Leader") {
            return acc;
        }

        const counterValue = card.Counter;
        const count = card.count || 1; // Use card.count if it exists, otherwise default to 1
        acc[counterValue] = (acc[counterValue] || 0) + count;
        return acc;
    }, {});


    const levelCount = selectedCards.reduce((counts, card) => {
        const level = card.Level;
        const count = card.count; // Assuming 'count' property holds the number of duplicates
        const levelKey = `Level ${level}`; // Adding "Level " prefix to level
        if (level > 0) {
            counts[levelKey] = (counts[levelKey] || 0) + count;
        }
        return counts;
    }, {});

    // Convert levelCount object to an array of key-value pairs
    const levelCountArray = Object.entries(levelCount);

    // Sort the array based on levels (the first element of each pair)
    levelCountArray.sort((a, b) => {
        // Extract the level from the key (remove "Level " prefix)
        const levelA = parseInt(a[0].replace("Level ", ""));
        const levelB = parseInt(b[0].replace("Level ", ""));
        return levelA - levelB;
    });    // Convert the sorted array back to an object
    const sortedLevelCount = Object.fromEntries(levelCountArray);


    // Load an image and return a Promise
    const loadImage = (src) => new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = reject;
    });

    // Crop an image and return its data URL
    const cropImage = async (src, centerXPercent, centerYPercent, cropWidth, cropHeight) => {
        const img = await loadImage(src);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;
        const cropX = imgWidth * (centerXPercent / 100) - cropWidth / 2;
        const cropY = imgHeight * (centerYPercent / 100) - cropHeight / 2;
        canvas.width = cropWidth;
        canvas.height = cropHeight;
        ctx.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
        return canvas.toDataURL('image/jpg');
    };



    // Create a gradient background if no background image is available
    const createGradientBackground = (ctx, colors) => {
        if (colors.length === 1) {
            ctx.fillStyle = colors[0];
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);
        } else {
            const gradient = ctx.createLinearGradient(0, 0, canvasWidth, canvasHeight);
            const step = 1 / (colors.length - 1);
            colors.forEach((color, index) => gradient.addColorStop(index * step, color));
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);
        }
    };

    const getBackgroundImage = (leaderCardColor) => {
        const leaderCardColorNew = leaderCardColor.toString();
        const colorKey = leaderCardColorNew ? leaderCardColorNew.toLowerCase() : uniqueColors.join('');
        let backgroundImageURL;
        if (selectedCardType === 'gundam') {
            backgroundImageURL = `/assets/exportbackgrounds/${selectedCardType}/black.png`;
        }
        else {
            backgroundImageURL = `/assets/exportbackgrounds/${selectedCardType}/${colorKey}.png`;
        }

        return new Promise((resolve) => {
            const img = new Image();
            img.src = backgroundImageURL;
            img.onload = () => resolve(backgroundImageURL);
            img.onerror = () => resolve(null);
        });
    };

    // Export canvas content as an image
    const exportAsImage = async () => {
        if (!selectedCards || selectedCards.length === 0) {
            console.log('No cards selected for export.');
            return;
        }

        const filteredCards = selectedCards.filter(card => !card['Category'].toLowerCase().includes('leader'));
        let leaderCards = [];
        let leaderCardColor = '';

        if (selectedCardType === 'fusionworld' || selectedCardType === 'optcg') {
            leaderCards = selectedCards.filter(card => card['Category'].toLowerCase().includes('leader'));
        } else if (selectedCardType === 'digimon') {
            const digiEggCards = selectedCards.filter(card => card['Category'].toLowerCase().includes('digi-egg'));
            if (digiEggCards.length > 0) {
                // Sort digi eggs by count in descending order and select the first one
                const selectedLeaderCard = digiEggCards.reduce((prev, current) => (prev.count > current.count) ? prev : current);
                leaderCards = [selectedLeaderCard];
                leaderCardColor = selectedLeaderCard['Color'];
            }
        }

        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');

        tempCanvas.width = canvasWidth;
        tempCanvas.height = canvasHeight;

        // Fill the background with white
        tempCtx.fillStyle = 'white';
        tempCtx.fillRect(0, 0, canvasWidth, canvasHeight);

        // Get the background image based on leader's color
        const backgroundImageURL = await getBackgroundImage(leaderCardColor);
        if (backgroundImageURL) {
            const backgroundImage = await loadImage(backgroundImageURL);
            tempCtx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);
            tempCtx.fillStyle = 'rgba(0, 0, 0, 0.45)';
            tempCtx.fillRect(0, 0, canvasWidth, canvasHeight);
        } else {
            createGradientBackground(tempCtx, uniqueColors);
        }


        // Draw the QR code
        const qrCodeDataURL = await QRCode.toDataURL(deckLink);
        const qrImage = await loadImage(qrCodeDataURL);
        const qrCodeSize = 150;
        const qrX = canvasWidth - qrCodeSize - containerPadding;
        const qrY = containerPadding;
        tempCtx.drawImage(qrImage, qrX, qrY, qrCodeSize, qrCodeSize);

        // Draw the header image if there are leader cards
        if (leaderCards.length > 1) {
            const headerImageWidth = headerImageSize.width;
            const headerImageHeight = headerImageSize.height;

            // Load images and draw them as a composite
            const images = await Promise.all(
                leaderCards.map(card => loadImage(`/card_images/${selectedCardType}/${card.Key}.webp`))
            );

            // Create an offscreen canvas for the composite image
            const compositeCanvas = document.createElement('canvas');
            const compositeCtx = compositeCanvas.getContext('2d');
            compositeCanvas.width = headerImageWidth;
            compositeCanvas.height = headerImageHeight;

            // Draw each image with equal proportions
            const portionWidth = headerImageWidth / leaderCards.length;
            images.forEach((img, index) => {
                const x = index * portionWidth;
                compositeCtx.drawImage(img, x, 0, portionWidth, headerImageHeight);
            });

            // Draw the composite header image onto the main canvas
            const compositeImage = await loadImage(compositeCanvas.toDataURL('image/png'));
            tempCtx.drawImage(compositeImage, containerPadding, containerPadding, headerImageSize.width, headerImageSize.height);
        }
        else {
            let leaderImageURL;
            if (selectedCardType === 'unionarena') {
                console.log(selectedCards[0]['CARD ID'][0]);
                leaderImageURL = `/card_images/${selectedCardType}/${selectedCards[0]['CARD ID'][0]}.webp`;
            }
            else if (leaderCards.length > 0) {
                console.log(leaderCards.Key);
                leaderImageURL = `/card_images/${selectedCardType}/${leaderCards[0].Key}.webp`;
            }

            if (leaderImageURL) {
                const croppedImage = await cropImage(leaderImageURL, 50, 30, 500, 300);
                const img = await loadImage(croppedImage);
                tempCtx.drawImage(img, containerPadding, containerPadding, headerImageSize.width, headerImageSize.height);
            }
        }

        console.log("drawing bar");
        console.log(sortedLevelCount);

        const graphData = Object.keys(counterCounts).length > 1 ? counterCounts : sortedLevelCount;
        const graphDataEntries = Object.entries(graphData);
        const hasMoreThanThreeBars = graphDataEntries.length > 3; // Check if there are more than 3 items

        // Draw the bar graph next to the header image
        const barGraphWidth = 300;
        const barGraphHeight = 200;
        const barGraphX = containerPadding + headerImageSize.width + 50;
        const barGraphY = containerPadding;

        // Define space between label and bar
        const labelSpacing = 20; // Adjust this value as needed

        tempCtx.font = 'Bold 21px Arial';
        tempCtx.textAlign = 'center';
        tempCtx.textBaseline = 'middle';

        // Function to format the counter value
        const formatCounterLabel = (counter) => {
            if (!counter) return '';
            if (selectedCardType === 'fusionworld' || selectedCardType === 'optcg') {
                const numberPart = parseInt(counter, 10); // Convert counter to a number
                const labelPart = 'K'; // Custom text
                return `${Math.floor(numberPart / 1000)}${labelPart}`; // Format as "2K" for 2000
            } else {
                const numberPart = counter.replace(/Level\s*/i, ''); // Remove "Level" prefix
                const labelPart = 'L'; // Custom text
                return `${labelPart}${numberPart}`; // Format as "L2" for Level 2
            }
        };

        if (hasMoreThanThreeBars) {
            // Vertical bar graph
            const barWidth = 40;
            const barSpacing = 10;
            const chartHeight = barGraphHeight - 40; // Leave space for labels
            let x = 0;

            graphDataEntries.forEach(([key, count], index) => {
                const barHeight = (count / Math.max(...Object.values(graphData))) * chartHeight;
                const barX = barGraphX + x * (barWidth + barSpacing);

                // Draw the bar with white fill and black border
                tempCtx.fillStyle = 'white'; // Bar color
                tempCtx.fillRect(barX, barGraphY + chartHeight - barHeight, barWidth, barHeight);
                tempCtx.strokeStyle = 'black'; // Border color
                tempCtx.lineWidth = 2; // Border thickness
                tempCtx.strokeRect(barX, barGraphY + chartHeight - barHeight, barWidth, barHeight);

                // Draw label inside the bar
                tempCtx.fillStyle = 'black'; // Label color
                tempCtx.fillText(count, barX + barWidth / 2, barGraphY + chartHeight - barHeight / 2);

                // Draw formatted counter label below the bar
                const formattedLabel = formatCounterLabel(key);
                tempCtx.fillStyle = 'white'; // Label color
                tempCtx.fillText(formattedLabel, barX + barWidth / 2, barGraphY + chartHeight + 20);

                x++;
            });
        } else {
            // Horizontal bar graph
            const barHeight = 40;
            const barSpacing = 10;
            const chartWidth = barGraphWidth - 40; // Leave space for labels
            let y = 0;

            graphDataEntries.forEach(([key, count], index) => {
                const barWidth = (count / Math.max(...Object.values(graphData))) * chartWidth;
                const barY = barGraphY + y * (barHeight + barSpacing);

                // Draw the bar with white fill and black border
                tempCtx.fillStyle = 'white'; // Bar color
                tempCtx.fillRect(barGraphX, barY, barWidth, barHeight);
                tempCtx.strokeStyle = 'black'; // Border color
                tempCtx.lineWidth = 2; // Border thickness
                tempCtx.strokeRect(barGraphX, barY, barWidth, barHeight);

                // Draw label inside the bar
                tempCtx.fillStyle = 'black'; // Label color
                tempCtx.fillText(count, barGraphX + barWidth / 2, barY + barHeight / 2);

                // Draw formatted counter label outside the bar
                const formattedLabel = formatCounterLabel(key);
                tempCtx.fillStyle = 'white'; // Label color
                tempCtx.fillText(formattedLabel, barGraphX - labelSpacing, barY + barHeight / 2);

                y++;
            });
        }

        // Draw the cards on the canvas
        const totalCardWidth = maxCardsPerRow * cardWidth + (maxCardsPerRow - 1) * 10;
        const startX = (canvasWidth - totalCardWidth - 2 * containerPadding) / 2 + containerPadding;
        let offsetX = startX;
        let offsetY = containerPadding + headerImageSize.height + 50;

        const cardImages = await Promise.all(
            filteredCards.map(card => loadImage(`/card_images/${selectedCardType}/${card.Key}.webp`))
        );
        // Function to draw rounded rectangle
        const drawRoundedRect = (ctx, x, y, width, height, radius) => {
            ctx.beginPath();
            ctx.moveTo(x + radius, y);
            ctx.arcTo(x + width, y, x + width, y + height, radius);
            ctx.arcTo(x + width, y + height, x, y + height, radius);
            ctx.arcTo(x, y + height, x, y, radius);
            ctx.arcTo(x, y, x + width, y, radius);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
        };

        // Draw the cards on the canvas with rounded edges
        cardImages.forEach((cardImg, i) => {
            const cardX = offsetX;
            const cardY = offsetY;
            const borderRadius = 20; // Adjust the radius for rounded corners

            // Create a clipping region for the rounded rectangle
            tempCtx.save();
            drawRoundedRect(tempCtx, cardX, cardY, cardWidth, cardHeight, borderRadius);
            tempCtx.clip();

            // Draw the card image inside the rounded rectangle
            tempCtx.drawImage(cardImg, cardX, cardY, cardWidth, cardHeight);

            // Restore the context to remove the clipping region
            tempCtx.restore();

            // Draw the circular bubble for the card count
            const countBubbleRadius = 20;
            const countX = cardX + cardWidth - countBubbleRadius * 2 - 10;
            const countY = cardY + 30 + countBubbleRadius;

            tempCtx.fillStyle = 'white';
            tempCtx.beginPath();
            tempCtx.arc(countX + countBubbleRadius, countY, countBubbleRadius, 0, 2 * Math.PI);
            tempCtx.fill();
            tempCtx.strokeStyle = 'black';
            tempCtx.lineWidth = 2;
            tempCtx.stroke();

            tempCtx.font = 'bold 30px Poppins, Arial, sans-serif';
            tempCtx.fillStyle = 'black';
            tempCtx.textAlign = 'center';
            tempCtx.textBaseline = 'middle';
            tempCtx.fillText(filteredCards[i].count, countX + countBubbleRadius, countY);

            if ((i + 1) % maxCardsPerRow === 0) {
                offsetX = startX;
                offsetY += cardHeight + cardPaddingBottom;
            } else {
                offsetX += cardWidth + 10;
            }

            if (offsetY + cardHeight > canvasHeight - containerPadding) {
                return;
            }
        });

        const logoImage = new Image();
        logoImage.src = 'assets/EgmanEventsLogoEAEA.png';
        // Adjust the logo position to be to the left of the QR code
        await new Promise(resolve => {
            logoImage.onload = () => {
                const logoWidth = 250;
                const logoHeight = 140;
                const logoX = qrX - logoWidth - 10;
                const logoY = containerPadding + (qrCodeSize - logoHeight) / 2;
                tempCtx.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);
                resolve();
            };
        });

        // Export the final image
        const image = tempCanvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        link.download = 'deck_image.jpg';
        link.href = image;
        link.click();
    };




    return (
        <Button className='filterButton' onClick={exportAsImage}>Save Image</Button>
    );
};

export default ImageExport;

